const Data = [
    {
        puzzle_id: -1,
        fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
        topic: "Шахматная доска и фигуры",
        subtopic: "Шахматная доска и фигуры",
        title: "Игра в шахматах всегда начинается с одной и той же позиции. Всего в игре представлены 6 шахматных фигур, у каждой из которых есть название, графический символ, и ее “стоимость”, определяемая количеством пешек равным ей по силе. Обратите внимание, что хоть король и равен 3 пешкам по силе, на деле он бесценен, ведь является самой главной фигурой в игре.",
        weight: null,
        mode: 'basic',
        difficulty: 'easy',
        variants: null,
        closed: true,
        image: "assets/images/pieceCosts.png",
    },
    {
        puzzle_id: -1,
        fen: "8/8/8/4K3/8/8/8/8 w - - 0 1",
        topic: "Король",
        subtopic: "Король",
        title: "Как было упомянуто в предыдущем уроке, король – это главная (хотя и не самая сильная) фигура в игре. Король, не имеющий для своих ходов препятствий, может передвинуться на одно поле в любом направлении",
        weight: null,
        mode: 'basic',
        difficulty: 'easy',
        variants: null,
        closed: true,
    },
    {
        puzzle_id: -1,
        fen: "8/8/8/4Q3/8/8/8/8 w - - 0 1",
        topic: "Ферзь",
        subtopic: "Ферзь",
        title: "Ферзь – самая сильная фигура в шахматах. Ферзь может передвигаться по горизонталям, вертикалям, диагоналям на любое количество полей.",
        weight: null,
        mode: 'basic',
        difficulty: 'easy',
        variants: null,
        closed: true,
    },
    {
        puzzle_id: -1,
        fen: "8/8/8/4R3/8/8/8/8 w - - 0 1",
        topic: "Ладья",
        subtopic: "Ладья",
        title: "Ладья – младшая сестра ферзя. Так же, как и ферзь, ладья передвигается на любое количество полей по горизонталям и вертикалям, но по диагоналям она ходить не может.",
        weight: null,
        mode: 'basic',
        difficulty: 'easy',
        variants: null,
        closed: true,
    },
    {
        puzzle_id: -1,
        fen: "8/8/8/4B3/8/8/8/8 w - - 0 1",
        topic: "Слон",
        subtopic: "Слон",
        title: "Слон – младший брат ферзя. Как и ферзь, слон передвигается на любое количество полей, но только по диагоналям. Все шахматные фигуры могут перемещаться по полям разных цветов, но это не относится к слону. Слон до конца партии будет ходить по полям одного цвета. Поэтому слоны называются белопольными и чернопольными.",
        weight: null,
        mode: 'basic',
        difficulty: 'easy',
        variants: null,
        closed: true,
    },
    {
        puzzle_id: -1,
        fen: "8/8/8/4N3/8/8/8/8 w - - 0 1",
        topic: "Конь",
        subtopic: "Конь",
        title: "Конь – самая необычная из фигур. Всем известно, что конь ходит буквой “Г”. Также важной особенностью этой фигуры является то, что он умеет перепрыгивать через другие фигуры.",
        weight: null,
        mode: 'basic',
        difficulty: 'easy',
        variants: null,
        closed: true,
    },
    {
        puzzle_id: -1,
        fen: "8/8/8/8/3P4/8/P7/8 w - - 0 1",
        topic: "Пешка",
        subtopic: "Пешка",
        title: "Пешка – единственная фигура, которой не разрешается делать ходы назад. Пешки передвигаются по вертикалям, только вперед и только на одно поле. Исключение – если пешка стоит на начальной позиции, то она может, по выбору шахматиста, двинуться на одно или на два поля.",
        weight: null,
        mode: 'basic',
        difficulty: 'easy',
        variants: null,
        closed: true,
    }
]

export default Data;
